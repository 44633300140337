import { gql } from '@apollo/client';

export function itemQuery(id) {
  const name = `ItemQuery_${id}`;
  return gql`
    query ${name}($id: ID!) {
      item(id: $id) {
        id
      }
    }
  `;
}

export function getItemDataQuery(id) {
  const name = `GetCurrentItem_${id}`;
  return gql`
    query ${name}($id: ID!) {
      item(id: $id) {
        id
        address
        isMine
        isBookmarked
        description
        leased
        rooms
        floor
        floors
        phones {
          ... on MaskedPhone {
            value
          }
          ... on Phone {
            value
          }
        }
        hasBillOfSale
        hasMortgage
        hasRepair
        state
        isKapitalbankApplicable
        contactName
        contactTypeName
        buildingTypeName
        longitude
        latitude
        displayedLocation
        path
        paidServices {
          id
          options {
            id
            name
            price
          }
          paymentMethods {
            id 
            balance
            name
          }
          activeUntil
          title
          targetType
        }
        business {
          ... on Agency {
            id
            itemsCount
            logoUrl
            name
            path
            viewsCount
          }
          ... on Residence {
            id
            website
            logoUrl
            contactEmail
            path
            companyName
          }
        }
        nearestLocations {
          ... on Location {
            path
            fullName
            id
          }
          ... on City {
            path
            name
            id
          }
        }
        updatedAt
        views
        isFeatured
        isVipped
        rejectReason
        location {
          fullName
          path
        }
        landArea {
          units
          value
        }
        area {
          units
          value
        }
        price {
          currency
          value
        }
        company {
          id
          name
          targetType
       }
        breadcrumbs {
          name
          path
        }
        category {
          hasFloor
          hasLandArea
          name
          pluralName
          slug
          title
       }
       city {
          id
          name
          path
          slug
       }
       photos {
          full
          f660x496
       }
      }
      flashMessages {
        type
        message
      }
    }
  `;
}

export function getComplaintReasonsQuery() {
  return gql`
    query GetComplaintReasons {
      reportReasons {
        id
        reason
      }
    }
  `;
}

const temp = 'just for eslint';

export default temp;
