import { gql } from '@apollo/client';

export const deleteBookmarkQuery = gql`
  mutation DeleteBookmark($itemId: ID!) {
    deleteBookmark(itemId: $itemId) {
      bookmark {
        itemId
      }
      errors {
        message
        path
      }
    }
  }
`;

export const createBookmarkQuery = gql`
  mutation CreateBookmark($itemId: ID!) {
    createBookmark(itemId: $itemId) {
      bookmark {
        itemId
      }
      errors {
        message
        path
      }
    }
  }
`;

export const deleteCompanyQuery = gql`
  mutation LogOut {
    logOut {
      profile {
        ... on CompanyProfile {
          id
        }
      }
      errors {
        message
      }
    }
  }
`;

export const sendAccessToken = gql`
  mutation Auth($accessToken: String!) {
    companyAuth(accessToken: $accessToken) {
      company {
        id
        name
        phone
      }
      errors {
        message
        path
      }
    }
  }
`;

export const createReportQuery = gql`
  mutation CreateReport(
    $clientMutationId: String
    $itemId: ID!
    $reportReasonId: ID!
    $email: String
    $description: String
  ) {
    createReport(
      input: {
        clientMutationId: $clientMutationId
        itemId: $itemId
        reportReasonId: $reportReasonId
        email: $email
        description: $description
      }
    ) {
      report {
        id
      }
      errors {
        code
        message
        path
      }
    }
  }
`;

export const createPaymentQuery = gql`
  mutation CreatePayment(
    $clientMutationId: String
    $paidServiceOptionId: ID!
    $paymentMethodId: ID!
    $targetId: ID!
    $targetType: PaidServiceTargetTypeEnum!
  ) {
    createPayment(
      input: {
        clientMutationId: $clientMutationId
        paidServiceOptionId: $paidServiceOptionId
        paymentMethodId: $paymentMethodId
        targetId: $targetId
        targetType: $targetType
      }
    ) {
      status
      url
      errors {
        code
        message
        path
      }
    }
  }
`;
